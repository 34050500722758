import axios from "axios";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";

function DopplePage() {
  const { tokenDayDatas } = useTokenDayDatas(500);

  useEffect(() => {
    if (tokenDayDatas.length) console.log(tokenDayDatas);
  }, [tokenDayDatas]);

  return (
    <div>
      <div className="container mx-auto">
        {tokenDayDatas.length && (
          <table className="table-auto container">
            <thead>
              <tr>
                <th className="px-4 py-2">Number</th>
                <th className="px-4 py-2">Symbol</th>
                <th className="px-4 py-2">Daily Admin Fee</th>
                <th className="px-4 py-2">Daily Swap Fee</th>
                <th className="px-4 py-2">Daily Txns</th>
                <th className="px-4 py-2">Daily Volume Token</th>
                <th className="px-4 py-2">Date</th>
              </tr>
            </thead>

            <tbody>
              {tokenDayDatas.map((tdd, i) => (
                <tr className={i % 2 === 0 ? "bg-gray-100" : ""} key={i}>
                  <td className="border px-4 py-2">{i + 1}</td>
                  <td className="border px-4 py-2">{tdd.token.symbol}</td>
                  <td className="border px-4 py-2">
                    {new BigNumber(tdd.dailyAdminFee).div(1e18).toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                    {new BigNumber(tdd.dailySwapFee).div(1e18).toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">{tdd.dailyTxns}</td>
                  <td className="border px-4 py-2">
                    {new BigNumber(tdd.dailyVolumeToken).div(1e18).toFixed(2)}
                  </td>

                  <td className="border px-4 py-2">
                    {new Date(tdd.date * 1000).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

interface TTokenDayData {
  token: {
    symbol: string;
  };
  dailyAdminFee: string;
  dailySwapFee: string;
  dailyTxns: string;
  dailyVolumeToken: string;
  date: number;
}

function useTokenDayDatas(days: number): { tokenDayDatas: TTokenDayData[] } {
  const [tokenDayDatas, setTokenDayDatas] = useState<TTokenDayData[]>([]);

  useEffect(() => {
    if (tokenDayDatas.length === 0) {
      axios
        .post(
          "https://api.thegraph.com/subgraphs/name/ekaomk/dopple-subgraph",
          `{"query":"{  tokenDayDatas(first: ${days}, orderBy: date, orderDirection: desc){    token{      symbol    }    dailyVolumeToken    dailyTxns    dailySwapFee    dailyAdminFee    date  }}","variables":null}`
        )
        .then((response) => {
          setTokenDayDatas(response.data.data.tokenDayDatas);
        });
    }
  });
  return { tokenDayDatas };
}

export default DopplePage;
